<template>
  <div class="w-full">
    <div v-if="!loading"
      class="
        mt-5
        px-5
        py-2
        rounded
        hidden
        lg:grid
        grid-cols-12
        bg-blue-light
        text-left
      "
    >
      <span class="col-span-3 text-blue-dark"><b>Id. de radicado</b></span>
      <span class="col-span-2 text-blue-dark"><b>Entidad</b></span>
      <span class="col-span-4 text-blue-dark mr-2"><b>Motivo de queja</b></span>
      <span class="col-span-1 text-blue-dark"><b>Estado</b></span>
      
       <span class="col-span-1 text-blue-dark"><b>Queja principal</b></span>
      
      <span class="col-span-1 text-blue-dark"><b>Acciones</b></span>
    </div>
    <div v-if="!loading">
    <ComplaintListItem
    
      v-for="(complaint, index) in complaints"
      :key="index"
      :complaint="complaint"
    />
    </div>
    
    <div v-if="!loading" class="mt-8 flex justify-between items-center lg:justify-end gap-4">
      <div class="text-gray-400 font-bold">
        Página {{ current }} de {{ total }}
      </div>
      <div class="flex gap-4">
        <a-button
          type="primary"
          ghost
          @click.native="prevPage"
          :disabled="prev"
        >
          Anterior
        </a-button>
        <a-button
          type="primary"
          ghost
          @click.native="nextPage"
          :disabled="next"
        >
          Siguiente
        </a-button>
      </div>
    </div>
    <div v-if="loading" class="flex justify-center items-center mt-32">
        <a-spin>
          <a-icon
            slot="indicator"
            type="loading"
            style="font-size: 64px"
            spin
          />
        </a-spin>
      </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ComplaintListItem from "./ComplaintListItem.vue";
export default {
  components: {
    ComplaintListItem,
  },
  data() {
    return {
      loading: false
    };
  },

  created() {
    this.loading = true;
    this.$store.dispatch("complaints/loadComplaints").then(
      res => {
          this.loading = false;
      }
    ).catch(
      err => {
          this.loading = false;
      }
    );
  },
  computed: {
    ...mapState({
      complaints: (state) => state.complaints.complaints,
      next: (state) => (state.complaints.nextPage ? false : true),
      prev: (state) => (state.complaints.prevPage ? false : true),
      total: (state) => state.complaints.totalPages,
      current: (state) => state.complaints.currentPage,
    }),
  },
  methods: {
    async nextPage() {
      await this.$store.dispatch("complaints/nextPage");
      window.scrollTo(0, 0);
    },
    async prevPage() {
      await this.$store.dispatch("complaints/prevPage");
      window.scrollTo(0, 0);
    },
  },
};
</script>