<template>
    <div class="w-full max-w-xl py-2 rounded-xl border-blue-dark border-2 flex flex-col sm:flex-row justify-around items-center">
        <div class="w-full py-2">
            <p><b class="text-blue-dark">Consumidor financiero</b></p>
            <p v-if="user">{{user.first_name }}</p>
        </div>
        <span class="block w-3/4 h-px sm:w-1 sm:h-16 bg-blue-dark"></span>
        <div class="w-full py-2">
            <p><b class="text-blue-dark">Correo electrónico</b></p>
            <p v-if="user">{{user.email}}</p>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        user: {
            require: true
        }
    },
}
</script>