var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full"},[(!_vm.loading)?_c('div',{staticClass:"mt-5 px-5 py-2 rounded hidden lg:grid grid-cols-12 bg-blue-light text-left"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5)]):_vm._e(),(!_vm.loading)?_c('div',_vm._l((_vm.complaints),function(complaint,index){return _c('ComplaintListItem',{key:index,attrs:{"complaint":complaint}})}),1):_vm._e(),(!_vm.loading)?_c('div',{staticClass:"mt-8 flex justify-between items-center lg:justify-end gap-4"},[_c('div',{staticClass:"text-gray-400 font-bold"},[_vm._v(" Página "+_vm._s(_vm.current)+" de "+_vm._s(_vm.total)+" ")]),_c('div',{staticClass:"flex gap-4"},[_c('a-button',{attrs:{"type":"primary","ghost":"","disabled":_vm.prev},nativeOn:{"click":function($event){return _vm.prevPage.apply(null, arguments)}}},[_vm._v(" Anterior ")]),_c('a-button',{attrs:{"type":"primary","ghost":"","disabled":_vm.next},nativeOn:{"click":function($event){return _vm.nextPage.apply(null, arguments)}}},[_vm._v(" Siguiente ")])],1)]):_vm._e(),(_vm.loading)?_c('div',{staticClass:"flex justify-center items-center mt-32"},[_c('a-spin',[_c('a-icon',{staticStyle:{"font-size":"64px"},attrs:{"slot":"indicator","type":"loading","spin":""},slot:"indicator"})],1)],1):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"col-span-3 text-blue-dark"},[_c('b',[_vm._v("Id. de radicado")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"col-span-2 text-blue-dark"},[_c('b',[_vm._v("Entidad")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"col-span-4 text-blue-dark mr-2"},[_c('b',[_vm._v("Motivo de queja")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"col-span-1 text-blue-dark"},[_c('b',[_vm._v("Estado")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"col-span-1 text-blue-dark"},[_c('b',[_vm._v("Queja principal")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"col-span-1 text-blue-dark"},[_c('b',[_vm._v("Acciones")])])
}]

export { render, staticRenderFns }